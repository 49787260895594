import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import { SafeHtmlParser } from "./safe-html-parser";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";

const Areas = ({ heading, body, popularLocations, button, button2 }) => {
	return (
		<section className="areas-section py-5 py-md-8 bg-green-light">
			<Container>
				<Row className="mb-4 text-center justify-content-center">
					<Col lg={9}>
						<h3 className="mb-2">{heading}</h3>
						{body && <SafeHtmlParser htmlContent={body} />}
						{button && (
							<Button
								href={button.url}
								target={button.target}
								variant="secondary"
								className="px-4 py-2 mt-3 w-100 w-md-auto "
							>
								{button.title}
							</Button>
						)}
					</Col>
				</Row>
				<Row className="justify-content-center mt-6">
					{popularLocations.map((location, index) => (
						<Col
							xs={12}
							md={6}
							xl={3}
							key={index}
							className="mb-4 d-flex justify-content-center"
						>
							<Link
								to={`/${location.popularLocationsFields.url}`}
								className="d-flex align-items-center text-decoration-none montserrat-semibold fs-6 green-link mb-2 d-flex justify-content-center"
							>
								<Card className="text-center location-card shadow-sm border-0">
									<Card.Body>
										<GatsbyImage
											variant="top"
											image={
												location.popularLocationsFields?.featuredImage
													?.localFile.childImageSharp.gatsbyImageData
											}
											alt={location.title}
											style={{ height: "200px" }}
											className="rounded-3"
										/>
										<Card.Title className="mt-4 mb-3">
											{location.title}
										</Card.Title>
										<span className="d-flex align-items-center text-decoration-none montserrat-semibold fs-6 green-link mb-2 d-flex justify-content-center">
											Explore Offices
											<StaticImage
												src={"../images/arrow-right.svg"}
												alt="Your Office People"
												layout="fixed"
												width={20}
												className="ms-2"
											/>
										</span>
									</Card.Body>
								</Card>
							</Link>
						</Col>
					))}
				</Row>
				<Row className="mt-4 text-center">
					{button2 && (
						<Col>
							<Button
								href={button2.url}
								target={button2.target}
								variant="transparent"
								className="px-4"
							>
								{button2.title}
							</Button>
						</Col>
					)}
				</Row>
			</Container>
		</section>
	);
};

export default Areas;
