import React from "react";
import { BgImage } from "gbimage-bridge";
import { Container, Row, Col } from "react-bootstrap";
import { SafeHtmlParser } from "./safe-html-parser";
import GetInTouchForm from "./get-in-touch-form";

const BookingForm = ({ heading, body, backgroundImage }) => {
	return (
		<section className="booking-form-section ">
			<BgImage
				image={backgroundImage?.localFile.childImageSharp.gatsbyImageData}
				className="booking-form__background position-relative py-5 py-lg-9"
			>
				<Container
					style={{ zIndex: 1 }}
					className="position-relative text-center  py-5"
				>
					<Row className="justify-content-center">
						<Col className="text-black pb-5" lg={8}>
							<h2 className="text-black mb-3">{heading}</h2>
							<SafeHtmlParser htmlContent={body} />
						</Col>
					</Row>
					<Row className="justify-content-center">
						<Col lg={5}>
							<div
								className="p-4 text-start"
								style={{
									borderRadius: "12px",
									border: "1px solid rgba(149, 193, 31, 0.24)",
									background: "white",
								}}
							>
								<GetInTouchForm />
							</div>
						</Col>
					</Row>
				</Container>
				<div
					style={{
						background:
							"linear-gradient(180deg, rgba(243, 250, 243, 0.95) 3.36%, rgba(243, 250, 243, 0.00) 100%)",
						zIndex: 0,
					}}
					className=" position-absolute w-100 h-100 top-0 start-0 "
				></div>
			</BgImage>
		</section>
	);
};

export default BookingForm;
